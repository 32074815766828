<template>
  <div class="actionlog">
    <div v-show="loading" class="loading">
      <img src="@/assets/loading.gif" alt="" />
    </div>
    <div v-show="!loading">
      <!-- 顶栏 -->
      <div class="actionlog-title">
        <div class="title">数据操作日志</div>
        <div class="flex-space"></div>
        <!-- <div class="actionlog-fliter">
          <div v-for="(item, index) in filterList" :key="index" class="actionlog-fliter-item">
            {{ item.name }}:{{ item.value }}
            <i class="el-icon-delete" @click="deleteFilterItem(item.name)"></i>
          </div>
          <el-button @click="deleteAllFilter" type="text" v-if="filterList.length > 0">
            重置
          </el-button>
        </div> -->
      </div>
      <div v-if="filterList.length > 0" class="datalist-title-filter">
        <div v-for="(item, index) in filterList" :key="index" class="datalist-title-filter-item">
          {{ item.name }}: {{ item.value }}
          <i class="el-icon-close" @click="deleteFilterItem(item.name)"></i>
        </div>
        <el-button
          v-if="filterList.length"
          class="datalist-title-button"
          type="text"
          @click="deleteAllFilter"
        >
          重置
        </el-button>
      </div>
      <eyao-table
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total-num="dataCount"
        ref="table"
        :height="'100%'"
        :height-sty="'85%'"
        :table-data="tableData"
        @filter-change="filterChange"
      >
        <el-table-column prop="ope_time" column-key="ope_time" label="操作时间" sortable>
          <template slot-scope="scope">
            {{ dateFormat(new Date(scope.row.ope_time * 1000), 'YYYY-MM-dd HH:mm:SS') }}
          </template>
        </el-table-column>
        <el-table-column prop="user_name" column-key="user_name" label="操作用户">
          <template slot="header" slot-scope="scope">
            <eyao-table-search-header
              property="user_name"
              title="操作用户"
              :scope="scope"
              @search="search"
            ></eyao-table-search-header>
          </template>
        </el-table-column>
        <el-table-column prop="data_name" column-key="data_name" label="操作数据">
          <template slot="header" slot-scope="scope">
            <eyao-table-search-header
              property="data_name"
              title="操作数据"
              :scope="scope"
              @search="search"
            ></eyao-table-search-header>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="ope_type"
          column-key="ope_type"
          label="操作类型"
          :filters="[
            { text: '新建', value: 1 },
            { text: '编辑', value: 2 },
            { text: '删除', value: 4 },
          ]"
          :filter-method="filterHandler"
        >
          <template slot-scope="scope">
            {{ scope.row.ope_type | format }}
          </template>
        </el-table-column> -->
        <el-table-column prop="ope_type" column-key="ope_type" label="操作类型">
          <template slot="header" slot-scope="scope">
            <eyao-table-search-header
              property="ope_type"
              title="操作类型"
              :scope="scope"
              form-type="select"
              :options="[
                { label: '新建', value: 1 },
                { label: '编辑', value: 2 },
                { label: '删除', value: 4 },
              ]"
              @search="search"
            ></eyao-table-search-header>
          </template>
          <template slot-scope="scope">
            {{ scope.row.ope_type | format }}
          </template>
        </el-table-column>
        <el-table-column prop="content" column-key="content" label="操作内容">
          <template slot="header" slot-scope="scope">
            <eyao-table-search-header
              property="content"
              title="操作内容"
              :scope="scope"
              form-type="select"
              :options="arr"
              @search="search"
            ></eyao-table-search-header>
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="" column-key="">
          <template slot-scope="scope">
            <el-button type="text" @click="gotoDetail(scope.row.log_id)">查看详情</el-button>
          </template>
        </el-table-column>
        <template v-slot:empty>
          <div class="no_data" v-if="!tableData.length && !loading">
            <img
              src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
              alt="未查询到相关数据"
            />
            未查询到相关数据
          </div>
        </template>
      </eyao-table>
      <el-drawer title="数据操作日志详情" :visible.sync="drawer" direction="rtl" size="640px">
        <div class="actiondetail">
          <!-- 顶部右侧返回按钮 -->
          <div class="actiondetail-title"></div>
          <!-- 中央信息显示部分 -->
          <div class="actiondetail-content">
            <!-- <div class="actiondetail-content-title">数据操作日志详情</div> -->
            <div class="actiondetail-content-info">
              <div class="actiondetail-content-info-title">操作时间</div>
              <div class="actiondetail-content-info-data">{{ ope_time }}</div>
            </div>
            <div class="actiondetail-content-info">
              <div class="actiondetail-content-info-title">操作用户</div>
              <div class="actiondetail-content-info-data">{{ user_name }}</div>
            </div>
            <div class="actiondetail-content-info">
              <div class="actiondetail-content-info-title">操作数据</div>
              <div class="actiondetail-content-info-link" @click="handleDetail">
                {{ data_name }}
              </div>
            </div>
            <div class="actiondetail-content-info">
              <div class="actiondetail-content-info-title">操作类型</div>
              <div class="actiondetail-content-info-data">{{ ope_type | format }}</div>
            </div>
            <div class="actiondetail-content-table">
              <div class="actiondetail-content-info-title">操作内容</div>
              <!-- 数据表格 -->
              <el-table
                :data="detailTableData"
                stripe
                class="tab"
                height="800"
                :header-cell-style="headercellstyle"
              >
                <el-table-column prop="field_name" label="字段名称"></el-table-column>
                <el-table-column prop="before_val" label="更新前"></el-table-column>
                <el-table-column prop="after_val" label="更新后"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import store from '@/store';
import eyaoTable from '@/components/table';
import eyaoTableSearchHeader from '@/components/table-search-header';
import { GetDataOperationLogList, GetDataOperationLogDetail } from '@/api/log';
import { dateFormat } from '@/utils/util';
export default {
  name: 'Actionlog',
  components: {
    eyaoTable,
    eyaoTableSearchHeader,
  },
  filters: {
    format(val) {
      switch (val) {
        case 1:
          return '新建';
        case 2:
          return '编辑';
        case 4:
          return '删除';
        default:
          '';
      }
    },
  },
  data() {
    return {
      arr: [
        { label: '机构名称', value: '机构名称' },
        { label: '二级分类', value: '二级分类' },
        { label: '类型细分', value: '类型细分' },
        { label: '上级机构名称', value: '上级机构名称' },
        { label: '机构简介', value: '机构简介' },
        { label: '医院级别', value: '医院级别' },
        { label: '所有制性质', value: '所有制性质' },
        { label: '是否医保', value: '是否医保' },
        { label: '医院营利性质', value: '医院营利性质' },
        { label: '卫健委名称', value: '卫健委名称' },
        { label: '审批机关', value: '审批机关' },
        { label: '诊疗科目', value: '诊疗科目' },
        { label: '法人', value: '法人' },
        { label: '企业负责人', value: '企业负责人' },
        { label: '曾用名', value: '曾用名' },
        { label: '英文名', value: '英文名' },
        { label: '别名', value: '别名' },
        { label: '行政区划', value: '行政区划' },
        { label: '地址', value: '地址' },
        { label: '经纬度', value: '经纬度' },
        { label: '邮编', value: '邮编' },
        { label: '主页', value: '主页' },
        { label: '电话', value: '电话' },
        { label: '邮箱', value: '邮箱' },
        { label: '微信公众号', value: '微信公众号' },
        { label: '注册号', value: '注册号' },
        { label: '注册地址', value: '注册地址' },
        { label: '注册资金', value: '注册资金' },
        { label: '组织机构代码', value: '组织机构代码' },
        { label: '经营方式', value: '经营方式' },
        { label: '经营范围', value: '经营范围' },
        { label: '所属行业', value: '所属行业' },
        { label: '经营状态', value: '经营状态' },
        { label: '公司类型', value: '公司类型' },
        { label: '统一社会信用代码', value: '统一社会信用代码' },
        { label: '纳税人识别号', value: '纳税人识别号' },
        { label: '成立日期', value: '成立日期' },
        { label: '特色科室', value: '特色科室' },
        { label: '病床数量', value: '病床数量' },
        { label: '门急诊数量', value: '门急诊数量' },
        { label: '科室数量', value: '科室数量' },
        { label: '执业医师数量', value: '执业医师数量' },

        { label: '医生姓名', value: '医生姓名' },
        { label: '姓名拼音', value: '姓名拼音' },
        { label: '性别', value: '性别' },
        { label: '所属机构名称', value: '所属机构名称' },
        { label: 'HCP类型', value: 'HCP类型' },
        { label: 'HCP状态', value: 'HCP状态' },
        { label: '挂牌科室', value: '挂牌科室' },
        { label: '标准科室', value: '标准科室' },
        { label: '学术头衔', value: '学术头衔' },
        { label: '专业职称', value: '专业职称' },
        { label: '行政职务', value: '行政职务' },
        { label: '执业许可证编码', value: '执业许可证编码' },
        { label: '颁发机构', value: '颁发机构' },
        { label: '医师级别', value: '医师级别' },
        { label: '执业类别', value: '执业类别' },
        { label: '执业地点', value: '执业地点' },
        { label: '执业范围', value: '执业范围' },
        { label: '多点执业数量', value: '多点执业数量' },
        { label: '证书颁发日期', value: '证书颁发日期' },
      ],
      headercellstyle: {
        background: '#F2F3F8',
        'box-shadow': 'inset 0px -1px 0px #E5EAF2',
      },
      drawer: false,
      pageSizes: [],
      pageSize: '',
      pageIndex: 1,
      current: 1,
      tableData: [],
      // 数据总量
      dataCount: 0,
      // 数据当前加载完成的页码
      dataIndex: 1,
      loading: true,
      filterObject: {},
      filterList: [],

      detailTableData: [],
      user_name: '',
      ope_time: '',
      data_name: '',
      ope_type: '',
      org_type: '',
      id: '',
      data_type: '',
    };
  },
  computed: {
    role() {
      const newRole = JSON.parse(localStorage.getItem('role'));
      // 页面刷新时
      if (newRole) {
        this.$store.commit('user/updateRole', newRole);
      } else {
        return this.$store.state.user.role;
      }

      return newRole;
    },
    needLoading() {
      return !(this.tableData.length === this.dataCount);
    },
  },
  watch: {
    role(newval) {
      if (newval !== 1) {
        this.$message.error('无权限进入');
        this.$router.push('/dashboard/home');
      }
    },
  },
  mounted() {
    if (this.role === 3) {
      this.$message.error('无权限进入');
      this.$router.push('/dashboard/home');
    }

    this.init();
  },
  created() {
    this.pageSize = this.g_pageSize;
    this.pageSizes = this.g_pageSizes;
    if (this.$route.query.id) {
      this.gotoDetail(this.$route.query.id);
    }
  },
  methods: {
    deatailInit(val) {
      // const { breadcrumb } = store.state.user;
      // breadcrumb.push({
      //   name: '数据操作日志详情',
      //   path: '/dashboard/action-detail',
      //   params: { id: this.$route.params.id },
      // });
      // store.commit('user/setBreadcrumb', breadcrumb);
      const req = {
        log_id: val,
      };
      GetDataOperationLogDetail(req).then(res => {
        console.log(res);
        this.user_name = res.user_name;
        this.ope_time = dateFormat(new Date(res.ope_time * 1000), 'YYYY-MM-dd HH:mm:SS');
        this.data_name = res.data_name;
        this.ope_type = res.ope_type;
        this.detailTableData = res.contents;
        this.org_type = res.org_type;
        this.id = res.data_id;
        this.data_type = res.data_type;
      });
    },
    handleDetail() {
      if (this.ope_type === 4) {
        this.$message.error('该数据已被删除，无法查看详情！');
        return false;
      }
      if (this.data_type !== 't_hcp') {
        const orgType = {
          医院: 'hospital',
          基层医疗卫生机构: 'hospital-ji',
          专业公共医疗卫生机构: 'hospital-pro',
          其他医疗卫生机构: 'hospital-other',
          经销商: 'dealer',
          药店: 'shop',
        };
        const org = orgType[this.org_type];
        // this.$router.push({
        //   name: 'data-detail',
        //   query: { id: this.id, source: org, name: this.data_name, type: 'preview' },
        // });

        const href = this.$router.resolve({
          path: '/dashboard/data-detail',
          query: {
            id: this.id,
            name: this.data_name,
            type: 'preview',
            source: org,
          },
        });
        window.open(href.href, '_blank');

        store.commit('user/setBreadcrumb', []);
      } else {
        // this.$router.push({
        //   name: 'data-detail',
        //   query: { id: this.id, source: 'doctor', name: this.data_name, type: 'preview' },
        // });
        const href = this.$router.resolve({
          path: '/dashboard/data-detail',
          query: {
            id: this.id,
            name: this.data_name,
            type: 'preview',
            source: 'doctor',
          },
        });
        window.open(href.href, '_blank');
        store.commit('user/setBreadcrumb', []);
      }
    },
    handleSizeChange(val) {
      if (this.filterList.length > 0) {
        const req = {
          uin: '',
          page_size: val,
          index: this.pageIndex,
        };
        // this.filterList.forEach((item) => {
        //   if (item.name === '操作用户') {
        //     req.user_name = item.value;
        //   }
        //   if (item.name === '操作数据') {
        //     req.data_name = item.value;
        //   }
        //   if (item.name === '操作内容') {
        //     req.content = item.value;
        //   }
        // });
        this.filterList.forEach(item => {
          if (item.name === '操作用户') {
            req.user_name = item.value;
          }
          if (item.name === '操作数据') {
            req.data_name = item.value;
          }
          if (item.name === '操作内容') {
            req.content = item.value;
          }
          if (item.name === '操作类型') {
            const obj = { 新建: 1, 编辑: 2, 删除: 4 };
            req.ope_type = obj[item.value];
          }
        });
        GetDataOperationLogList(req).then(res => {
          this.tableData = res.data_operation_log_list || [];
          this.dataCount = res.total_num;
          // this.dataIndex = 2;
        });
      } else {
        this.pageSize = val;
        this.init();
      }
    },
    handleCurrentChange(val) {
      if (this.filterList.length > 0) {
        const req = {
          uin: '',
          page_size: this.pageSize,
          index: val,
        };
        // this.filterList.forEach((item) => {
        //   if (item.name === '操作用户') {
        //     req.user_name = item.value;
        //   }
        //   if (item.name === '操作数据') {
        //     req.data_name = item.value;
        //   }
        //   if (item.name === '操作内容') {
        //     req.content = item.value;
        //   }
        // });
        this.filterList.forEach(item => {
          if (item.name === '操作用户') {
            req.user_name = item.value;
          }
          if (item.name === '操作数据') {
            req.data_name = item.value;
          }
          if (item.name === '操作内容') {
            req.content = item.value;
          }
          if (item.name === '操作类型') {
            const obj = { 新建: 1, 编辑: 2, 删除: 4 };
            req.ope_type = obj[item.value];
          }
        });
        GetDataOperationLogList(req).then(res => {
          this.tableData = res.data_operation_log_list || [];
          this.dataCount = res.total_num;
          // this.dataIndex = 2;
        });
      } else {
        this.pageIndex = val;
        this.init();
      }
    },
    dateFormat,
    init() {
      this.loading = true;
      const req = {
        uin: this.$route.params.uin !== '' ? this.$route.params.uin : '',
        index: this.pageIndex,
        page_size: this.pageSize,
      };
      GetDataOperationLogList(req)
        .then(res => {
          this.tableData = res.data_operation_log_list || [];
          this.dataCount = res.total_num;
          this.dataIndex = 2;
          this.loading = false;
        })
        .catch(err => {
          this.$message.error(err.message);
          this.loading = false;
        });
      const { breadcrumb } = store.state.user;
      breadcrumb.push({
        name: '用户操作日志',
        path: '/dashboard/action-log',
        params: { uin: this.$route.params.uin },
      });
      store.commit('user/setBreadcrumb', breadcrumb);

      if (JSON.stringify(this.$route.params) !== '{}') {
        this.filterChange({ 操作用户: [this.$route.params.name] });
      }
    },
    // dataLoading() {
    //   // 不重复发送加载请求
    //   if (this.dataCount !== this.tableData.length && this.loading !== false) {
    //     return;
    //   }
    //   this.loading = true;
    //   let req = {
    //     uin: '',
    //     page_size: this.g_pageSize,
    //     index: this.dataIndex,
    //   };
    //   if (this.filterList.length < 1) {
    //     req = {
    //       uin: '',
    //       page_size: this.g_pageSize,
    //       index: this.dataIndex,
    //     };
    //   }
    //   this.filterList.forEach((item) => {
    //     if (item.name === '操作用户') {
    //       req.user_name = item.value;
    //     }
    //     if (item.name === '操作数据') {
    //       req.data_name = item.value;
    //     }
    //     if (item.name === '操作内容') {
    //       req.content = item.value;
    //     }
    //   });
    //   GetDataOperationLogList(req).then((res) => {
    //     this.tableData = this.tableData.concat(res.DataOperationLogList);
    //     this.dataCount = res.total_num;
    //     this.dataIndex += 1;
    //     this.loading = false;
    //   });
    // },
    search(item, item2) {
      this.dataCount = 0;
      console.log(item, item2);
      const options = {
        user_name: '操作用户',
        data_name: '操作数据',
        content: '操作内容',
        ope_type: '操作类型',
      };
      if (item2 !== '') {
        const key = options[item];
        const obj = {};
        if (item === 'ope_type') {
          const arr = ['新建', '编辑', '', '删除'];
          obj[key] = [arr[item2 - 1]];
        } else {
          obj[key] = [item2];
        }
        console.log(obj);
        this.filterChange(obj);
      }

      console.log(this.filterList);
      let req = {
        uin: '',
        page_size: this.pageSize,
        index: 1,
      };
      if (this.filterList.length < 1) {
        req = {
          uin: '',
          page_size: this.pageSize,
          index: 1,
        };
      }
      if (this.filterList.length > 0) {
        this.filterList.forEach(item => {
          if (item.name === '操作用户') {
            req.user_name = item.value;
          }
          if (item.name === '操作数据') {
            req.data_name = item.value;
          }
          if (item.name === '操作内容') {
            req.content = item.value;
          }
          if (item.name === '操作类型') {
            const obj = { 新建: 1, 编辑: 2, 删除: 4 };
            req.ope_type = obj[item.value];
          }
        });
      }
      this.current = 1;
      GetDataOperationLogList(req).then(res => {
        this.tableData = res.data_operation_log_list || [];
        this.dataCount = res.total_num;
        this.dataIndex = 2;
      });
    },
    filterHandler(value, row, column) {
      const { property } = column;
      return row[property] === value;
    },
    // 每次通过table组件原生调整筛选时更新筛选条件信息
    filterChange(filter) {
      Object.keys(filter).forEach(key => {
        this.filterObject[key] = filter[key];
      });
      this.updateFilterList();
    },
    updateFilterList() {
      const list = [];
      Object.keys(this.filterObject).forEach(key => {
        if (this.filterObject[key].length !== 0) {
          list.push({ name: key, value: this.filterObject[key].join(' ') });
        }
      });
      this.filterList = list;
    },
    // 筛选条件被手动删除时调用table方法更新table内筛选信息
    deleteFilterItem(key, delay) {
      this.filterObject[key] = [];
      this.$refs.table.clearFilter([key]);
      this.updateFilterList();
      console.log('剩下', this.filterList);
      if (!delay) {
        this.dataCount = 0;
        let req = {
          uin: '',
          page_size: this.g_pageSize,
          index: 1,
        };
        if (this.filterList.length < 1) {
          req = {
            uin: '',
            page_size: this.g_pageSize,
            index: 1,
          };
        }
        // this.filterList.forEach((item) => {
        //   if (item.name === '操作用户') {
        //     req.user_name = item.value;
        //   }
        //   if (item.name === '操作数据') {
        //     req.data_name = item.value;
        //   }
        //   if (item.name === '操作内容') {
        //     req.content = item.value;
        //   }
        // });
        this.filterList.forEach(item => {
          if (item.name === '操作用户') {
            req.user_name = item.value;
          }
          if (item.name === '操作数据') {
            req.data_name = item.value;
          }
          if (item.name === '操作内容') {
            req.content = item.value;
          }
          if (item.name === '操作类型') {
            const obj = { 新建: 1, 编辑: 2, 删除: 4 };
            req.ope_type = obj[item.value];
          }
        });
        GetDataOperationLogList(req).then(res => {
          this.tableData = res.data_operation_log_list || [];
          this.dataCount = res.total_num;
          this.dataIndex = 2;
        });
      }
    },
    deleteAllFilter() {
      this.dataCount = 0;
      Object.keys(this.filterObject).forEach(key => {
        this.deleteFilterItem(key, true);
      });
      const req = {
        uin: '',
        index: 1,
        page_size: this.pageSize,
      };
      GetDataOperationLogList(req).then(res => {
        this.tableData = res.data_operation_log_list || [];
        this.dataCount = res.total_num;
        this.dataIndex = 2;
      });
    },
    gotoDetail(id) {
      // console.log(id);
      // this.$router.push({ name: 'action-detail', query: { id } });
      this.drawer = true;
      this.deatailInit(id);
    },
  },
};
</script>
<style lang="scss" scoped>
@import './action-log.scss';
@import './action-detail.scss';
</style>
